'use strict';

// hamburger

const hamBtn = document.querySelector('#ham-btn');
const contactBtn = document.querySelector('#contact-btn');
const hamBg = document.querySelector('#ham-bg');
const spMenu = document.querySelector('#sp-menu');


hamBtn.addEventListener('click', () => {
    hamBtn.classList.toggle('active');
    contactBtn.classList.toggle('close');
    hamBg.classList.toggle('open');
    spMenu.classList.toggle('open');
});

spMenu.addEventListener('click', () => {
    hamBtn.classList.remove('active');
    contactBtn.classList.remove('close');
    hamBg.classList.remove('open');
    spMenu.classList.remove('open');
});

// Swiper

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/modules/pagination'

const swiper = new Swiper('.swiper', {
    direction: 'horizontal',
    loop: true,
    modules: [Navigation, Pagination],
    disableOnInteraction: false,
    slidesPerView: 1.25,
    spaceBetween: 10,
    centeredSlides: true,

    breakpoints: {
        431: {
            loop: false,
            slidesPerView: 4,
            spaceBetween: 30,
            loop: false,
            centeredSlides: false,
        }
    },

    pagination: {
        el: '.swiper-pagination',
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

//popup

const menuImgs = document.querySelectorAll('.menu__data');
const imgModal = document.querySelector('#img-modal');
const backBtn = document.querySelector('#back-btn');
const popupImgs = document.querySelectorAll('.popup__img');

menuImgs.forEach((menuImg) => {
    menuImg.addEventListener('click', (e) => {
        const imgData = e.currentTarget.dataset.menu;
        console.log(imgData);

        popupImgs.forEach((popupImg) => {
            const popupData = popupImg.dataset.popup;

            if ( imgData === popupData ) {
                popupImg.classList.add('open');
            }
        });

        imgModal.classList.add('open');
    });
});

backBtn.addEventListener('click', () => {
    imgModal.classList.remove('open');
    popupImgs.forEach((popupImg) => {
        popupImg.classList.remove('open');
    });
});

imgModal.addEventListener('click', () => {
    imgModal.classList.remove('open');
    popupImgs.forEach((popupImg) => {
        popupImg.classList.remove('open');
    });
});

popupImgs.forEach((popupImg) => {
    popupImg.addEventListener('click', ( e ) => {
        e.stopPropagation();
    });
});